import { ReactNode, useEffect, useRef } from 'react';
import { MAX_SCREEN_SIZE } from '@/lib/constants';
type Props = {
  mainContent?: ReactNode;
  secondaryContent?: ReactNode;
  hasFeaturedSection?: boolean;
  hasFeaturedBackgroundColor?: boolean;
};
export function NavigationGridContainer({
  mainContent,
  secondaryContent,
  hasFeaturedBackgroundColor,
  hasFeaturedSection
}: Props) {
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const sectionContainerRef = useRef<HTMLDivElement | null>(null);
  const mainContentRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const updateBackgroundHeight = () => {
      const currentHeaderWidth = gridContainerRef.current?.offsetWidth || 0;
      const isDesktopView = currentHeaderWidth > MAX_SCREEN_SIZE;
      // Remove all grid column classes first
      sectionContainerRef.current?.classList.remove('grid-cols-1', 'grid-cols-3', 'grid-cols-4');
      if (isDesktopView) {
        const gridClass = hasFeaturedSection ? 'grid-cols-4' : 'grid-cols-3';
        sectionContainerRef.current?.classList.add(gridClass);
      } else {
        sectionContainerRef.current?.classList.add('grid-cols-1');
      }
    };
    window.addEventListener('resize', updateBackgroundHeight);
    updateBackgroundHeight();
    return () => window.removeEventListener('resize', updateBackgroundHeight);
  }, [hasFeaturedSection, gridContainerRef, sectionContainerRef, mainContentRef]);
  return <div className="w-full flex justify-center relative px-6 md:px-8 xl:px-16 xl-plus:px-0" data-testid="navigation-grid-container" ref={gridContainerRef} style={{
    background: `linear-gradient(to right, #ffffff 50%, ${hasFeaturedSection && hasFeaturedBackgroundColor ? '#edeff3' : '#ffffff'} 50%)`
  }} data-sentry-component="NavigationGridContainer" data-sentry-source-file="NavigationGridContainer.tsx">
			<div className="w-full max-w-[1440px] grid z-20" ref={sectionContainerRef}>
				<div className="bg-white col-span-3" ref={mainContentRef}>
					{mainContent}
				</div>
				{hasFeaturedSection && <div className="col-span-1" style={{
        backgroundColor: hasFeaturedSection && hasFeaturedBackgroundColor ? '#edeff3' : '#ffffff'
      }}>
						{secondaryContent}
					</div>}
			</div>
		</div>;
}